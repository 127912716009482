import React, { useState } from 'react'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import { Target } from '@revolut/icons'
import {
  CardContentTypes,
  getFinalGradeTitleFromRating,
  getKpiGrades,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { KPIsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'
import { DeliverablesCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/DeliverablesCardTable'
import { NoPersonalGoalsBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/NoPersonalGoalsBanner'
import { VStack } from '@revolut/ui-kit'
import { getGradesWithExpectations } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { getHideFinalRating } from '@src/pages/Forms/EmployeePerformanceViewLayout/utils'

export const DeliverablesCardView = connect(() => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const hasKPI = !!values.summary?.kpis_section
  const hasDeliverables = hasKPI || !!values.summary?.deliverables
  const gradesMap = values.grade_label_mapping
  const [goalsStat, setGoalsStat] = useState<number>()

  const deliverablesGrades = getKpiGrades(gradesMap, false)
  const deliverablesGradesWithExp = getGradesWithExpectations(deliverablesGrades, null)
  const kpiGrades = getKpiGrades(gradesMap, true)
  const kpiGradesWithExp = getGradesWithExpectations(kpiGrades, null)

  if (!hasDeliverables) {
    return null
  }

  const hideFinalRating = getHideFinalRating(values)

  return (
    <>
      {hasKPI ? (
        <Card
          data={values}
          renderExpandedContent={() => (
            <KPI
              isViewMode
              reviewData={values.summary}
              kpiGrades={kpiGradesWithExp}
              gradesMap={gradesMap}
            />
          )}
          additionalInfo={
            <KPIsCardTable gradesMap={gradesMap} setGoalsStat={setGoalsStat} />
          }
          stat={goalsStat}
          type={CardContentTypes.KPI}
          title="Goals"
          icon={Target}
          fields={[
            {
              field: 'summary.kpis_section.rating',
              title: 'Contribution and impact',
              grades: kpiGradesWithExp,
              gradeRecommendation: null,
            },
          ]}
          finalRating={
            hideFinalRating
              ? undefined
              : getFinalGradeTitleFromRating(
                  gradesMap,
                  values.summary?.kpis_section?.rating,
                )
          }
          isViewMode
        />
      ) : (
        <Card
          data={values}
          renderExpandedContent={expContentField => (
            <Deliverables
              isViewMode
              reviewData={values.summary}
              deliverablesGrades={deliverablesGradesWithExp}
              gradesMap={gradesMap}
              selectedField={expContentField}
            />
          )}
          additionalInfo={
            <VStack space="s-16">
              <NoPersonalGoalsBanner team={values.team} />
              <DeliverablesCardTable gradesMap={gradesMap} />
            </VStack>
          }
          type={CardContentTypes.DELIVERABLES}
          title="Goals"
          icon={Target}
          fields={[
            {
              field: `summary.deliverables.rating`,
              title: 'Contribution and impact',
              grades: deliverablesGradesWithExp,
              gradeRecommendation: null,
            },
          ]}
          justification={values?.summary?.deliverables?.skipped_section_justifications}
          finalRating={
            hideFinalRating
              ? undefined
              : getFinalGradeTitleFromRating(
                  gradesMap,
                  values.summary?.deliverables?.rating,
                )
          }
          isViewMode
        />
      )}
    </>
  )
})
